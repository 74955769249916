import '../assets/styles/formLogin.css';
import { useHistory } from "react-router-dom";
import {useForm} from "react-hook-form";
import {useFetch} from "../hooks/fetchHook";
import {useState} from "react";
import {toast} from "react-toastify";

export const FormRecoveryAccount = () => {

    const { register, handleSubmit } = useForm();
    const [ fetchRequest ] = useFetch();
    const history = useHistory();
    const [loading, setLoading] = useState(false);

    const goLogin = () => {
        history.push('/');
    }

    const onSubmit = (data: any) => {
        setLoading(true);
        const url = `/usuarios/request_password_reset?email=${data.username}`;
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        // @ts-ignore
        fetchRequest(url, data, null ).subscribe(
            (response: any) => {
                setLoading(false);
                if (response?.status === "success") {
                    toast(response?.message || 'Correcto!!', {type: 'success'});
                    history.push('/');
                } else {
                    toast(response?.message || 'Error al recuperar la cuenta.', {type: 'error'});
                }
            }
        );
    }

    return (
        <>
            <div>
                <h1 className='primary-color'>Recuperar cuenta</h1>
                <p className='text-muted'>Ingresa tu dirección de correo electrónico.</p>
            </div>
            <form className='form-login' onSubmit={handleSubmit(onSubmit)}>
                <div className='input-field-group'>
                    <label htmlFor="username">Correo electrónico</label>
                    <input
                        type="email"
                        {...register('username', { required: true, min: 10 })}
                    />
                </div>
                <div>
                    <button className='button' type='submit' disabled={loading}>
                        { loading && <div className="loader"/> } Enviar código
                    </button>
                </div>
                <p className='text-center cursor-pointer text-muted'>
                    Ya tienes una cuenta <span className='link' onClick={goLogin}>Iniciar sesión</span>
                </p>
            </form>
        </>
    );
}
