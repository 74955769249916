import {useFetch} from "./fetchHook";
import {useEffect, useState} from "react";
import {ServiceInterface} from '../interfaces/service.interface';
import {toast} from "react-toastify";
import {AuthService} from "../services/AuthService";

export const useServicesHook = () => {

    const [,goFetch,] = useFetch();
    const [listServices, setListServices] = useState<ServiceInterface[]>([]);

    useEffect(() => {
        getAllServices();
    }, []);

    const getAllServices = async () => {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        // @ts-ignore
        const response = await goFetch('/subscripcion/get_servicios_activos');
        if (response?.status === "success") {
            setListServices(response?.data);
        } else {
            setListServices([]);
            if (response?.code === 401) {
                toast('Tu sesión ha caducado.', { type: 'error' });
                localStorage.clear();
                AuthService.setLogin({});
            }
        }
    }

    return [listServices];
}
