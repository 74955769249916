import '../assets/styles/AuthLayout.css';
import Lexis from "../assets/images/lexis.svg";
import Phone from "../assets/images/Phone.svg";
import {LogosList} from "../pages/LogosList";
import {Logo} from "../components/Logo";
import {useEffect, useState} from "react";

export const AuthLayout = ({ children }: any) => {

    const [bannerImg, setBannerImg] = useState('');

    const openExternalService = (proRedirectUrl: string) => {
        window.open(proRedirectUrl, '_self');
    }

    useEffect(() => {
        getBanners();
    }, []);

    const getBanners = () => {
        fetch('https://m2admin.lexis.com.ec/graphql', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "query": "query BannerLaas{laas_banner{laas_banner_002,laas_banner_003,lass_banner_001}}"
            })
        }).then(value => value.json())
            .then(response => {
                if (response?.data?.laas_banner) {
                    const banner = response?.data?.laas_banner[0];
                    const imgBanner = `https://m2admin.lexis.com.ec/assets/${banner?.lass_banner_001 || banner?.lass_banner_002 || banner?.lass_banner_003}`;
                    setBannerImg(imgBanner);
                }
            });
    }


    return (
        <>
            <div className='container-login-screen'>
                <div className='bg_login_screen' style={{backgroundImage: `url(${bannerImg})`}}/>
                <div className='container_form_login_screen'>
                    <div className='inner_form_login_screen'>
                        <div className='header_logo_contact'>
                            <div>
                                <img className='logo cursor-pointer' src={Lexis} alt="LEXIS" onClick={() => openExternalService('https://www.lexis.com.ec/')}/>
                            </div>
                            <div className='d-flex-inline-phone'>
                                <img src={Phone} alt="PHONE" height={20}/> &nbsp;
                                <a href="https://api.whatsapp.com/send?phone=593995886995&text=Hola!%20Necesito%20informaci%C3%B3n%20acerca%20de%3A" className='link primary-color'>
                                     Contáctanos
                                </a>
                            </div>
                        </div>
                        <div className='animate__animated animate__fadeIn'>
                            {children}
                        </div>
                        <div className='container-logos'>
                            {/* {
                                LogosList.map((value, key) => (
                                    <Logo key={key} logo={value}/>
                                ))
                            } */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
