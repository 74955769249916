import {useForm} from "react-hook-form";
import {useFetch} from "../hooks/fetchHook";
import {toast} from "react-toastify";
import {useState} from "react";
import Eye from "../assets/images/view.png";

export const ChangePassword = ({ setOpenModal }: any) => {

    const { register, handleSubmit, formState: { errors }, setError } = useForm();
    const [ fetchRequest ] = useFetch();
    const [viewPassword, setViewPassword] = useState('password');

    const onSubmit = (data: any) => {
        if (data.confirmPassword !== data.newPassword) {
            setError('confirmPassword', {
                message: 'La contraseña ingresada no coincide.',
            });
            return;
        }

      // @ts-ignore
        fetchRequest('/usuarios/change_password', data).subscribe(
            (response: any) => {
                if (response?.status === "success") {
                  sessionStorage.setItem('b', btoa(data.confirmPassword));
                  toast(response?.message || 'La contraseña ha sido restablecida exitosamente.', { type: 'success' });
                    setOpenModal(false);
                } else {
                    toast(response?.message || 'Error al restablecer la contraseña.', { type: 'error' });
                }
            }
        )
    }

    const getError = (input: any) => {
        return input?.message || 'Por favor rellena este campo.';
    }

    const changePassword = () => {
        const newState = (viewPassword === 'password') ? 'text' : 'password';
        setViewPassword(newState);
    }

    return (
        <>
            <div className='modal_change_password'>
                <div className='inner_modal'>
                    <h3>Cambio de contraseña</h3>
                    <div>
                        <p>
                            Para continuar, debe establecer una contraseña diferente <br/> a la proporcionada por el sistema.
                        </p>
                    </div>
                    <form className='form-change_password' onSubmit={handleSubmit(onSubmit)}>
                        <div className='input-field-group container-password-input'>
                            <label htmlFor="pass_one">Nueva contraseña</label>
                            <input
                                type={viewPassword}
                                {...register('newPassword', { required: true })}
                            />
                            <div className={'eye-content'} onClick={changePassword}>
                                <img src={Eye} alt="ver" height={20}/>
                            </div>
                            {errors.newPassword && <div className="invalid-feedback">{getError(errors.newPassword)}</div>}
                        </div>
                        <div className='input-field-group container-password-input'>
                            <label htmlFor="pass_one_repeat">Repetir la contraseña</label>
                            <input
                                type={viewPassword}
                                {...register('confirmPassword', { required: true })}
                            />
                            <div className={'eye-content'} onClick={changePassword}>
                                <img src={Eye} alt="ver" height={20}/>
                            </div>
                            {errors.confirmPassword && <div className="invalid-feedback">{getError(errors.confirmPassword)}</div>}
                        </div>
                        <div>
                            <button className='button' type='submit'>Cambiar contraseña</button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}
