import { AuthLayout } from "../layouts/AuthLayout";
import {FormRecoveryAccount} from "../components/FormRecoveryAccount";

export const RecoveryAccountScreen = () => {
    return (
        <>
            <AuthLayout>
                <FormRecoveryAccount/>
            </AuthLayout>
        </>
    );
}
