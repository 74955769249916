import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { LoginScreen } from "../pages/LoginScreen";
import { RecoveryAccountScreen } from "../pages/RecoveryAccountScreen";
import { ChangePasswordScreen } from "../pages/ChangePasswordScreen";
import {ConfirmUserScreen} from "../pages/ConfirmUserScreen";

export const AuthRouter = () => {
    return (
        <div className="auth__main">
            <div className="auth__box-container">
                <Switch>
                    <Route 
                        exact
                        path="/usuario/nueva-contrasena"
                        component={ ChangePasswordScreen }
                    />
                    <Route 
                        exact
                        path="/confirm-user"
                        component={ ChangePasswordScreen }
                    />

                    <Route
                        exact
                        path="/usuario/recuperar-cuenta"
                        component={ RecoveryAccountScreen }
                    />

                    <Route
                        exact
                        path="/usuario/confirmar-cuenta"
                        component={ ConfirmUserScreen }
                    />

                    <Route 
                        exact
                        path="/"
                        component={ LoginScreen }
                    />

                    <Redirect to="/" />
                </Switch>
            </div>

        </div>
    )
}
