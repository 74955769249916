import { FormLogin } from "../components/FormLogin";
// import MaintenanceMessage from "../components/MaintenanceMessage";
import { AuthLayout } from "../layouts/AuthLayout";

export const LoginScreen = () => {
    return (
        <>
            <AuthLayout>
                <FormLogin/>
                {/*<MaintenanceMessage/>*/}
            </AuthLayout>
        </>
    );
}
