import '../assets/styles/HomeScreen.css';
import {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {toast} from "react-toastify";
import {useFetch} from "../hooks/fetchHook";
import {config} from "../config";
import Lexis from "../assets/images/lexis.svg";

export const ConfirmUserScreen = () => {

    const history = useHistory();
    const [ fetchRequest ] = useFetch();

    useEffect(() => {
        const params = window.location?.search?.substr(1);
        if (params) {
            const [, code] = params.split("=");
            if (code) {
                goActiveUser(code);
            } else {
                history.push('/');
            }
        } else {
            history.push('/');
        }
    }, []);

    const goActiveUser = (code: any) => {
        const url = `/usuarios/activar_usuario/${code}`;
        // @ts-ignore
        fetchRequest(url, {}, null ).subscribe(
            (response: any) => {
                if (response?.status === "success") {
                    toast(response?.message || 'Correcto!!', {type: 'success'});
                    history.push('/');
                } else {
                    toast(response?.message || 'Error inesperado en el servicio.', {type: 'error'});
                }
            }
        );
    }

    return (
      <>
          <div className="container-validator">
              <div className='center-center'>
                  <img className='logo cursor-pointer' src={Lexis} alt="LEXIS"/>
                  <p className={'text-muted'}>Validando cuenta...</p>
                  <br/>
                  <div className="loader"/>
              </div>
          </div>
      </>
  );
}
