import { Observable } from 'rxjs';
import { config } from "../config";

export const useFetch = () => {

    const fetchRequest = (url: string, dataSend: any = {}, headers: any = null): Observable<any> => {
        return new Observable<any>(subscriber => {
            goFetch(url, dataSend, headers).then((response: any) => {
                subscriber.next(response);
            })
        });
    }

    const goFetch = (url: string, dataSend: any = {}, headers: any = null) => {

        const user = JSON.parse(localStorage.getItem('user') as string);

        if (!headers) {
            headers = {
                'Content-Type': 'application/json',
            };
            if (user?.access_token) {
                headers = {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.access_token}`
                };
            }
        }

        if (typeof dataSend === 'object') {
            dataSend = JSON.stringify(dataSend);
        }

        return fetch(`${config.API_URL}${url}`, {
            method: 'POST',
            body: dataSend,
            headers
        }).then(res => res.json()).catch(reason => null);
    }

    const saveAnalytic = (token: string): Observable<any> => {

        var url = `${config.ANALYTICS_URL}/login`;
        var data = { 
            dispositivo: 'WEB'
        };

        return new Observable<any>(subscriber => {
            fetch(url, {
                method: 'POST', // or 'PUT'
                body: JSON.stringify(data), // data can be `string` or {object}!
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            }).then(res => res.json())
                .catch(error => subscriber.error(error))
                .then(resp => subscriber.next(resp));
        });
    }

    return [fetchRequest, goFetch,saveAnalytic];
}
