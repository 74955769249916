export const config = {
    //Local
    //API_URL: 'http://localhost:8092/api/v1',
    //ANALYTICS_URL: 'http://localhost:8082/api/v1',
    //Prod
    /*API_URL: 'https://serviceslaas.lexis.com.ec/api/v1',
    ANALYTICS_URL: 'https://laasanalytics.lexis.com.ec/api/v1',*/
    API_URL: 'https://api.lexis.com.ec/api/v1/laas',
    ANALYTICS_URL: 'https://laasanalytics.lexis.com.ec/api/v1',
}
