import '../assets/styles/HomeScreen.css';
import Logout from '../assets/images/logout.png';
import LexisLight from "../assets/images/lexis_light.svg";
import {useServicesHook} from "../hooks/servicesHook";
import {useEffect, useState} from "react";
import {ChangePassword} from "../components/ChangePassword";
import {config} from "../config";
import {useFetch} from "../hooks/fetchHook";
import {toast} from "react-toastify";

export const HomeScreen = () => {

    const [ listServices ] = useServicesHook();
    const [fetchRequest, goFetch,saveAnalytic] = useFetch();
    const [ openModal, setOpenModal ] = useState(false);
    const [ logoutStatus, setLogoutStatus ] = useState(false);
    const [ loadingProducts, setLoadingProducts ] = useState(true);

    useEffect(() => {
        checkChangePassword();
    }, []);

    const checkChangePassword = async () => {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        // @ts-ignore
        const response = await goFetch('/usuarios/check_credentials');
        if (response?.status === "success") {
            setLoadingProducts(false);
            setOpenModal(response?.data?.usuCredendialExpirada);
        }
    }

    const openExternalService = (proRedirectUrl: string) => {
        window.open(proRedirectUrl, '_self');
    }

    const logout = async () => {
        setLogoutStatus(true);
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        // @ts-ignore
        const response = await goFetch('/usuarios/cerrar_sesion');
        if (response?.status === "success") {
            toast(response?.message || 'Good Bay!!', {type: 'success'});
            localStorage.clear();
        } else {
            localStorage.clear();
        }
        setLogoutStatus(false);
        document.location.reload();
    }

    const openService = (item: any) => {
        // Validamos que existan las credenciales
        if (!sessionStorage.getItem('a') || !sessionStorage.getItem('a')) {
            toast('Error en la sesión.', {type: 'error'});
            logout();
        }

        toast('Redireccionando al producto...', {type: 'info', position: 'top-center'});
        // Obtenemos las credenciales
        // todo desencriptar antes de enviar
        const username = atob(sessionStorage.getItem('a') as string);
        const password = atob(sessionStorage.getItem('b') as string);
        const servicePars = item.proNombre.replace(/\s+/g, '_');

        // Nos logeamos en el servicio
        const dataSend = `username=${username}&password=${encodeURIComponent(password)}&grant_type=password`;
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Basic ${ btoa(`${servicePars}:LEXIS_SERVICE_${servicePars}`) }`
        }

        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        // @ts-ignore
        fetchRequest('/security/oauth/token', dataSend, headers ).subscribe(
            (response: any) => {
                if (response?.access_token) {
                    // Almacenamos la sesión del usuario
                    console.log('Nuevo token: ', response);
                     // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                    // @ts-ignore
                    saveAnalytic(response.access_token).subscribe((resp:any) => {
                        console.log("Save anl")
                    }, (error:any) => console.log(error));
                    const url = `${item.proRedirectUrl}?token=${response?.access_token}&refresh_token=${response?.refresh_token}`;
                    openExternalService(url)
                } else {
                    toast(response?.message || 'No se ha podido validar sus credenciales, por favor escriba a soporte.', {type: 'error'});
                }
            }
        )
    }

    return (
      <>
          { openModal && <ChangePassword setOpenModal={setOpenModal}/> }
          <div className='home_screen animate__animated animate__fadeIn'>
              <div className="container_header">
                  <img className='logo animate__animated animate__fadeInDown cursor-pointer' onClick={() => openExternalService('https://www.lexis.com.ec/')} src={LexisLight} alt="LEXIS"/>
              </div>
              <div className="container_footer_home animate__animated">
                  <p className='text-muted'>Al iniciar sesión usted está aceptando los <a href="https://drive.google.com/file/d/1y96OY1XeOSAqbbA328C1lwNCFKbDcIK1/view" target={'_blank'}>términos y condiciones</a> de uso de Lexis</p>
              </div>
              <div className="container-services">
                  <div className="inner_container_services animate__animated animate__fadeInUpBig">
                      <div className='text-right'>
                          <button disabled={logoutStatus} className={'btn-logout'} onClick={logout}>
                              <div className='center-horizontal'>
                                  <img src={Logout} height={20} alt="Salir"/> Salir
                              </div>
                          </button>
                      </div>
                      <p>
                          <b>
                              Por favor seleccione el producto al que desea ingresar:
                          </b>
                      </p>
                      <div className='container-logos-services'>
                          {
                              (listServices) ?
                                  listServices.map((value, key: number) => (
                                      <div
                                          onClick={() => openService(value)}
                                          key={key}
                                          className='p-service cursor-pointer'>
                                          <img height={100} width={100} src={`${config.API_URL}/servicios/image/${value.proIcono}`} alt="img"/>
                                          <div className='border-bottom-color animate__animated animate__fadeInUp' style={{ backgroundColor: value.proColor }}/>
                                      </div>
                                  ))
                                  :
                                  <div>No hay servicios...</div>
                          }
                          {
                              (loadingProducts) &&
                              <div className='center-center animate__animated animate__fadeInDown'>
                                  <p>Por favor espere mientras cargamos sus productos....</p>
                              </div>
                          }
                      </div>
                  </div>
              </div>
          </div>
      </>
  );
}
