import { AuthLayout } from "../layouts/AuthLayout";
import {FormRecoveryPassword} from "../components/FormRecoveryPassword";

export const ChangePasswordScreen = () => {
    return (
        <>
            <AuthLayout>
                <FormRecoveryPassword/>
            </AuthLayout>
        </>
    );
}
