import React, { useEffect, useState } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Redirect
} from 'react-router-dom';

import { AuthRouter } from './AuthRouter';
import { PrivateRoute } from './PrivateRoute';

import { PublicRoute } from './PublicRoute';
import { HomeScreen } from "../pages/HomeScreen";
import { AuthService } from "../services/AuthService";

export const AppRouter = () => {

    const [ checking, setChecking ] = useState(true);
    const [ isLoggedIn, setIsLoggedIn ] = useState(false);

    AuthService.getIsLogged().subscribe(({user}) => {
        if (user?.access_token) {
            setIsLoggedIn(true);
        } else {
            setIsLoggedIn(false);
        }
    });

    useEffect(() => {
        validateSession();
    }, [ setChecking, setIsLoggedIn ]);

    const validateSession = () => {
        if (localStorage.getItem('user')) {
            setIsLoggedIn(true);
        }
        setChecking(false);
    }

    if ( checking ) {
        return (
            <div>
                Loading...
            </div>
        )
    }

    return (
        <Router>
            <div>
                <Switch>

                    <PrivateRoute
                        exact
                        isAuthenticated={ isLoggedIn }
                        path="/home"
                        component={ HomeScreen }
                    />

                    <PublicRoute 
                        path="/"
                        component={ AuthRouter }
                        isAuthenticated={ isLoggedIn }
                    />

                    <Redirect to="/" />

                </Switch>
            </div>
        </Router>
    )
}
