import '../assets/styles/formLogin.css';
import { useHistory } from "react-router-dom";
import {useForm} from "react-hook-form";
import {useFetch} from "../hooks/fetchHook";
import {useState, useEffect} from "react";
import {toast} from "react-toastify";
import Eye from '../assets/images/view.png';

export const FormRecoveryPassword = () => {

    const [codeActive, setCodeActive] = useState('');
    const [loading, setLoading] = useState(false);
    const { register, handleSubmit, formState: { errors }, setError } = useForm();
    const [ fetchRequest ] = useFetch();
    const [viewPassword, setViewPassword] = useState('password');
    const history = useHistory();

    useEffect(() => {
        const params = window.location?.search?.substr(1);
        if (params) {
            const [, code] = params.split("=");
            if (code) {
                setCodeActive(code);
            } else {
                history.push('/');
            }
        } else {
            history.push('/');
        }
    }, []);

    const changePassword = () => {
        const newState = (viewPassword === 'password') ? 'text' : 'password';
        setViewPassword(newState);
    }

    const onSubmit = (data: any) => {
        if (data.confirmPassword !== data.newPassword) {
            setError('confirmPassword', {
                message: 'La contraseña ingresada no coincide.',
            });
            return;
        }
        setLoading(true);
        const url = `/usuarios/reset_password`;
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        // @ts-ignore
        fetchRequest(url, {preToken: codeActive, ...data}, null ).subscribe(
            (response: any) => {
                setLoading(false);
                if (response?.status === "success") {
                    toast(response?.message || 'Correcto!!', {type: 'success'});
                    history.push('/');
                } else {
                    toast(response?.message || 'Error al establecer la nueva contraseña', {type: 'error'});
                }
            }
        );
    }

    const getError = (input: any) => {
        return input?.message || 'Por favor rellena este campo.';
    }

    return (
        <>
            <div>
                <h1 className='primary-color'>Recuperar cuenta</h1>
                <p className='text-muted'>Establece una nueva contraseña para continuar.</p>
            </div>
            <form className='form-login' onSubmit={handleSubmit(onSubmit)}>
                <div className='input-field-group container-password-input'>
                    <label htmlFor="pass_one">Nueva contraseña</label>
                    <input
                        type={viewPassword}
                        placeholder='Contraseña'
                        {...register('newPassword', { required: true })}
                    />
                    <div className={'eye-content'} onClick={changePassword}>
                        <img src={Eye} alt="ver" height={20}/>
                    </div>
                    {errors.newPassword && <div className="invalid-feedback">{getError(errors.newPassword)}</div>}
                </div>
                <div className='input-field-group container-password-input'>
                    <label htmlFor="pass_one_repeat">Repetir la contraseña</label>
                    <input
                        type={viewPassword}
                        placeholder='Repetir contraseña'
                        {...register('confirmPassword', { required: true })}
                    />
                    <div className={'eye-content'} onClick={changePassword}>
                        <img src={Eye} alt="ver" height={20}/>
                    </div>
                    {errors.confirmPassword && <div className="invalid-feedback">{getError(errors.confirmPassword)}</div>}
                </div>
                <div>
                    <button className='button' disabled={loading} type='submit'>
                        { loading && <div className="loader"/> } Establecer contraseña
                    </button>
                </div>
            </form>
        </>
    );
}
